import styled from "styled-components";
import React from "react";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import { SeparatorLine } from "../styles/sep-line";
import { blue, gray700, gray800, secondary, white } from "../styles/colors";
import { StyledButton } from "../styles/buttons";
import { Section } from "../styles/section";
import {
    justifyContentCenter,
    textCenter,
    textJustify,
} from "../styles/bootstrap";
import {
    bgPrimary,
    bgWhite,
} from "../styles/theme";
import { fontAlt, fontWeight700 } from "../styles/fonts";
import { LetterSpacing2 } from "../styles/letter-spacing";
import intl from "react-intl-universal";

type Props = {};

export class Anouncement extends React.Component<Props> {
    createMarkup(text: string) {
        return { __html: text };
    }

    openInNewTab = (url: string) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    render() {
        return (
            <>
                <StyledAnouncement>
                    <div className="container">
                        <div className="row annoucement-content">
                            <div className="col-lg-12 col-xl-12">
                                <span className="announcement-icon">🎉</span>
                                <a href="https://laruchequebec.com/fr/projets/devenez-une-lutine-pour-un-enfant-issu-de-milieux-defavorises#offres">
                                    {intl.get("landing.announcementMessage")}
                                </a>
                            </div>
                        </div>
                    </div>
                </StyledAnouncement>
            </>
        );
    }
}

const GradientLength = 3;
const StyledAnouncement = styled.header`

background: ${secondary};
background: linear-gradient(90deg, rgba(255,144,69,0) 0%, rgba(255,144,69,0.9) ${GradientLength}%, rgba(255,144,69,0.9) ${100 - GradientLength}%, rgba(255,144,69,0) 100%);

  ${justifyContentCenter}
    right: 0;
    left: 0;

  .annoucement-content div{
    margin-top: 5px;
    margin-bottom: 5px;
    span {
      font-size: 22px;
      margin-right: 5px;
      user-select: none;
    }
    a {
      color: ${white};
      font-weight: bold;
      user-select: none;
    }
  }  
`;
